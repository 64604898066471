import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TerminalTable = ({ terminals }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Exchange ID</TableCell>
          <TableCell>Terminal ID</TableCell>
          <TableCell>Transactions Count</TableCell>
          <TableCell>Transactions Amount</TableCell>
          <TableCell>Exchange Commision</TableCell>
          <TableCell>Fiat Conversion</TableCell>
          <TableCell>Tx Processing</TableCell>
          <TableCell>Amount Due</TableCell>
          {/* Add more columns as needed */}
        </TableRow>
      </TableHead>
      <TableBody>
        {terminals.map((terminal, index) => (
          <TableRow key={index}>
            <TableCell>{terminal.exchangeID}</TableCell>
            <TableCell>{terminal.terminalID}</TableCell>
            <TableCell>{terminal.transactionsCount}</TableCell>
            <TableCell>{terminal.transactionsAmount}</TableCell>
            <TableCell>{terminal.exchangeCommission}</TableCell>
            <TableCell>{terminal.fiatConversion}</TableCell>
            <TableCell>{terminal.txProcessing}</TableCell>
            <TableCell style={{ color: "green" }}>
              {terminal.amountDue}
            </TableCell>
            {/* Add more cells as needed */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const DataTable = ({ data }) => (
  <div>
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <h2>Blokko Information</h2>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Report ID</TableCell>
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{data.blokko.reportID}</TableCell>
                <TableCell>{data.blokko.timeStamp}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>

    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <h2>Acquirer Information</h2>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Acquirer ID</TableCell>
                <TableCell>Transactions Count</TableCell>
                <TableCell>Transactions Amount</TableCell>
                <TableCell>Exchange Commission</TableCell>
                <TableCell>Fiat Conversion</TableCell>
                <TableCell>Tx Processing</TableCell>
                <TableCell>Amount Due</TableCell>
                {/* Add more columns as needed */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{data.acquirerID}</TableCell>
                <TableCell>{data.acquirer.transactionsCount}</TableCell>
                <TableCell>{data.acquirer.transactionsAmount}</TableCell>
                <TableCell>{data.acquirer.exchangeCommission}</TableCell>
                <TableCell>{data.acquirer.fiatConversion}</TableCell>
                <TableCell>{data.acquirer.txProcessing}</TableCell>
                <TableCell style={{ color: "green" }}>
                  {data.acquirer.amountDue}
                </TableCell>
                {/* Add more cells as needed */}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>

    {data.holdings.holding.map((holding, holdingIndex) => (
      <div key={holdingIndex} id={holdingIndex} style={{ padding: "20px" }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id={holdingIndex}
          >
            <h2>Holding Information {holdingIndex}</h2>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Merchant Holding</TableCell>
                    <TableCell>Merchant Holding ID</TableCell>
                    <TableCell>Transactions Count</TableCell>
                    <TableCell>Transactions Amount</TableCell>
                    <TableCell>Exchange Commision</TableCell>
                    <TableCell>Fiat Conversion</TableCell>
                    <TableCell>Tx Processing</TableCell>
                    <TableCell>Amount Due</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{holding.merchantHolding}</TableCell>
                    <TableCell>{holding.merchantHoldingID}</TableCell>
                    <TableCell>{holding.transactionsCount}</TableCell>
                    <TableCell>{holding.transactionsAmount}</TableCell>
                    <TableCell>{holding.exchangeCommission}</TableCell>
                    <TableCell>{holding.fiatConversion}</TableCell>
                    <TableCell>{holding.txProcessing}</TableCell>
                    <TableCell style={{ color: "green" }}>
                      {holding.amountDue}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        {holding.groups.group.map((merchantGroup, groupIndex) => (
          <div key={groupIndex} style={{ padding: "20px" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id={groupIndex}
              >
                <h3>Merchant Groups {groupIndex}</h3>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Merchant Group</TableCell>
                        <TableCell>Merchant Group ID</TableCell>
                        <TableCell>Transactions Count</TableCell>
                        <TableCell>Transactions Amount</TableCell>
                        <TableCell>Exchange Commision</TableCell>
                        <TableCell>Fiat Conversion</TableCell>
                        <TableCell>Tx Processing</TableCell>
                        <TableCell>Amount Due</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{merchantGroup.merchantGroup}</TableCell>
                        <TableCell>{merchantGroup.merchantGroupID}</TableCell>
                        <TableCell>{merchantGroup.transactionsCount}</TableCell>
                        <TableCell>
                          {merchantGroup.transactionsAmount}
                        </TableCell>
                        <TableCell>{merchantGroup.exchangeCommission}</TableCell>
                        <TableCell>{merchantGroup.fiatConversion}</TableCell>
                        <TableCell>{merchantGroup.txProcessing}</TableCell>
                        <TableCell style={{ color: "green" }}>
                          {merchantGroup.amountDue}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            {merchantGroup.merchants.merchant.map((merchant, merchantIndex) => (
              <div key={merchantIndex} style={{ padding: "20px" }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id={merchantIndex}
                  >
                    <h4>Merchants {merchantIndex}</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Merchant Name</TableCell>
                            <TableCell>Store</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Transactions Count</TableCell>
                            <TableCell>Transactions Amount</TableCell>
                            <TableCell>Exchange Commision</TableCell>
                            <TableCell>Fiat Conversion</TableCell>
                            <TableCell>Tx Processing</TableCell>
                            <TableCell>Amount Due</TableCell>
                            {/* Add more columns as needed */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{merchant.name}</TableCell>
                            <TableCell>{merchant.store}</TableCell>
                            <TableCell>{merchant.ID}</TableCell>
                            <TableCell>{merchant.transactionsCount}</TableCell>
                            <TableCell>{merchant.transactionsAmount}</TableCell>
                            <TableCell>{merchant.exchangeCommission}</TableCell>
                            <TableCell>{merchant.fiatConversion}</TableCell>
                            <TableCell>{merchant.txProcessing}</TableCell>
                            <TableCell style={{ color: "green" }}>
                              {merchant.amountDue}
                            </TableCell>
                            {/* Add more cells as needed */}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="terminal-header"
                  >
                    <h5>Terminals</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TerminalTable terminals={merchant.terminals.terminal} />
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        ))}
      </div>
    ))}
  </div>
);

export default DataTable;

export default function jsonToCsv(json) {
  let csv = "Report ID,Time Stamp (UTC),,,,,,,,,,\n";
  csv += `${json.blokko.reportID},${json.blokko.timeStamp},,,,,,,,,,\n`;
  csv +=
    "Acquirer ID,Transactions Count,Transactions Amount,Exchange Commission,Fiat Conversion,Tx Processing,Amount Due,,,,\n";
  csv += `${json.acquirerID},${json.acquirer.transactionsCount},${json.acquirer.transactionsAmount},${json.acquirer.exchangeCommission},${json.acquirer.fiatConversion},${json.acquirer.txProcessing},${json.acquirer.amountDue},,,,\n`;

  json.holdings.holding.forEach((holding) => {
    csv +=
      ",Merchant Holding,Merchant Holding ID,Transactions Count,Transactions Amount,Exchange Commision,Fiat Conversion,Tx Processing,Amount Due,,\n";
    csv += `,${holding.merchantHolding},${holding.merchantHoldingID},${holding.transactionsCount},${holding.transactionsAmount},${holding.exchangeCommission},${holding.fiatConversion},${holding.txProcessing},${holding.amountDue},,\n`;

    holding.groups.group.forEach((group) => {
      csv +=
        ",,Merchant Group,Merchant Group ID,Transactions Count,Transactions Amount,Exchange Commision,Fiat Conversion,Tx Processing,Amount Due,\n";
      csv += `,,${group.merchantGroup},${group.merchantGroupID},${group.transactionsCount},${group.transactionsAmount},${group.exchangeCommission},${group.fiatConversion},${group.txProcessing},${group.amountDue},\n`;

      group.merchants.merchant.forEach((merchant) => {
        csv +=
          ",,,Merchant Name,Store,ID,Transactions Count,Transactions Amount,Exchange Commision,Fiat Conversion,Tx Processing,Amount Due\n";
        csv += `,,,${merchant.name},${merchant.store},${merchant.ID},${merchant.transactionsCount},${merchant.transactionsAmount},${merchant.exchangeCommission},${merchant.fiatConversion},${merchant.txProcessing},${merchant.amountDue}\n`;

        merchant.terminals.terminal.forEach((terminal) => {
          csv +=
            ",,,,Exchange ID,Terminal ID,Transactions Count,Transactions Amount,Exchange Commision,Fiat Conversion,Tx Processing,Amount Due\n";
          csv += `,,,,${terminal.exchangeID},${terminal.terminalID},${terminal.transactionsCount},${terminal.transactionsAmount},${terminal.exchangeCommission},${terminal.fiatConversion},${terminal.txProcessing},${terminal.amountDue}\n`;
        });
      });
    });
  });

  return csv;
}

export function jsonToCsvPeriodic(json) {
  let csv = "Report ID,Time Stamp (UTC),,,,,,,,,,\n";
  csv += `${json.blokko.reportID},${json.blokko.timeStamp},,,,,,,,,,\n`;
  csv +=
    "Acquirer ID,Transactions Count,Transactions Amount,Exchange Commission,Fiat Conversion,Tx Processing,Amount Due,,,,\n";
  csv += `${json.acquirerID},${json.acquirer.transactionsCount},${json.acquirer.transactionsAmount},${json.acquirer.exchangeCommission},${json.acquirer.fiatConversion},${json.acquirer.txProcessing},${json.acquirer.amountDue},,,,\n`;

  json.holdings.holding.forEach((holding) => {
    csv +=
      ",Merchant Holding,Merchant Holding ID,Transactions Count,Transactions Amount,Exchange Commision,Fiat Conversion,Tx Processing,Amount Due,,\n";
    csv += `,${holding.merchantHolding},${holding.merchantHoldingID},${holding.transactionsCount},${holding.transactionsAmount},${holding.exchangeCommission},${holding.fiatConversion},${holding.txProcessing},${holding.amountDue},,\n`;

    holding.groups.group.forEach((group) => {
      csv +=
        ",,Merchant Group,Merchant Group ID,Transactions Count,Transactions Amount,Exchange Commision,Fiat Conversion,Tx Processing,Amount Due,\n";
      csv += `,,${group.merchantGroup},${group.merchantGroupID},${group.transactionsCount},${group.transactionsAmount},${group.exchangeCommission},${group.fiatConversion},${group.txProcessing},${group.amountDue},\n`;

      group.merchants.merchant.forEach((merchant) => {
        csv +=
          ",,,Merchant Name,Store,ID,Transactions Count,Transactions Amount,Exchange Commision,Fiat Conversion,Tx Processing,Amount Due\n";
        csv += `,,,${merchant.name},${merchant.store},${merchant.ID},${merchant.transactionsCount},${merchant.transactionsAmount},${merchant.exchangeCommission},${merchant.fiatConversion},${merchant.txProcessing},${merchant.amountDue}\n`;

        merchant.terminals.terminal.forEach((terminal) => {
          csv +=
            ",,,,Exchange ID,Terminal ID,Transactions Count,Transactions Amount,Exchange Commision,Fiat Conversion,Tx Processing,Amount Due\n";
          csv += `,,,,${terminal.exchangeID},${terminal.terminalID},${terminal.transactionsCount},${terminal.transactionsAmount},${terminal.exchangeCommission},${terminal.fiatConversion},${terminal.txProcessing},${terminal.amountDue}\n`;
        });
      });
    });
  });

  return csv;
}

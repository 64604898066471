// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { CssBaseline, createTheme, ThemeProvider } from "@mui/material";
import Dashboard from "./DashboardPage";

const theme = createTheme({
  typography: {
    fontFamily: "Arial",
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          {/* redirect to dashboard if the rout is / */}
          <Redirect exact from="/" to="/dashboard" />
          <Route path="/dashboard" component={Dashboard} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;

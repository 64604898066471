import * as React from "react";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DataTable from "./table";
import FormMerchantTerminal from "./form_merchant_terminal";
import FormReport from "./form_report";
// import SearchForm from "./search_form";
// import PeriodicForm from "./periodic_form";
import Logo from "./logo.jpeg";
import Button from "@mui/material/Button";
import Keycloak from "keycloak-js";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// import SearchIcon from "@mui/icons-material/Search";
// import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

const keycloakCONFIG = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI,
});

keycloakCONFIG
  .init({ checkLoginIframe: false, onLoad: "check-sso" })
  .then((auth) => {
    if (keycloakCONFIG.token) {
      console.log(keycloakCONFIG);
      localStorage.setItem(
        "rol",
        keycloakCONFIG.idTokenParsed["acquirer-rol-data"] === "admin" ? -99 : 1
      );
      localStorage.setItem("token", keycloakCONFIG.token);
      localStorage.setItem(
        "acquirerID",
        keycloakCONFIG.idTokenParsed["acquirer-id-data"]
      );
      localStorage.setItem(
        "acquirerName",
        keycloakCONFIG.idTokenParsed["acquirer-name-data"]
      );
      localStorage.setItem("email", keycloakCONFIG.idTokenParsed.email);
    }
    if (auth !== true) {
      keycloakCONFIG.login();
    }
    keycloakCONFIG.onTokenExpired = () => {
      localStorage.removeItem("token");
    };
  });

const ImageContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "auto",
  marginBottom: "16px",
});

const StyledImage = styled("img")({
  width: "100%",
  height: "auto",
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Blokko
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function Dashboard() {
  const [open, setOpen] = React.useState(true);
  const [data, setData] = React.useState({});
  const [openSnack, setSnackOpen] = React.useState(false);
  const [rol, setRol] = React.useState(false);
  const [messageSnack, setMessageOpen] = React.useState(
    "No transaction for this date"
  );
  const [selectedOption, setSelectedOption] = React.useState("report");
  const [severity, setSeverity] = React.useState("warning");
  const [nameSection, setNameSection] = React.useState(
    "Daily Clearing & Settlement Report"
  ); // added state to track selected option

  useEffect(() => {
    if (localStorage.getItem("rol") == -99) {
      setRol(true);
    }
  }, []);

  const handleClose = () => {
    setSnackOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    // Handle logout logic here
    keycloakCONFIG
      .logout()
      .then((success) => {
        console.log("User logout success ", success);
      })
      .catch((error) => {
        console.log("User logout error ", error);
      });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <Alert severity={severity} onClose={handleClose}>
          {messageSnack}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {nameSection} / {localStorage.getItem("acquirerID")} /{" "}
            {localStorage.getItem("acquirerName")} /{" "}
            {localStorage.getItem("email")}
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {rol && (
            <ListItemButton
              selected={selectedOption === "merchant_terminal"}
              onClick={() => {
                setSelectedOption("merchant_terminal");
                setNameSection("Merchant & Terminal Enrollment");
              }}
            >
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Merchant & Terminal" />
            </ListItemButton>
          )}
          <ListItemButton
            selected={selectedOption === "report"}
            onClick={() => {
              setSelectedOption("report");
              setNameSection("Daily Clearing & Settlement Report");
            }}
          >
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton>
          {/* <ListItemButton
            selected={selectedOption === "search"}
            onClick={() => {
              setSelectedOption("search");
              setNameSection("Transaction Search");
            }}
          >
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Transaction Search" />
          </ListItemButton>
          <ListItemButton
            selected={selectedOption === "periodic_report"}
            onClick={() => {
              setSelectedOption("periodic_report");
              setNameSection("Periodic Report");
            }}
          >
            <ListItemIcon>
              <ReceiptLongIcon />
            </ListItemIcon>
            <ListItemText primary="Periodic Report" />
          </ListItemButton> */}
        </List>
        <ImageContainer>
          <StyledImage src={Logo} alt="Blokko" />
        </ImageContainer>
      </Drawer>

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {selectedOption === "merchant_terminal" && (
            <FormMerchantTerminal
              setData={setData}
              setSnackOpen={setSnackOpen}
              setMessageOpen={setMessageOpen}
              setSeverity={setSeverity}
            />
          )}
          {selectedOption === "report" && (
            <FormReport
              setData={setData}
              setSnackOpen={setSnackOpen}
              setMessageOpen={setMessageOpen}
              setSeverity={setSeverity}
            />
          )}
          {/* {selectedOption === "search" && (
            <SearchForm setData={setData} setSnackOpen={setSnackOpen} setMessageOpen={setMessageOpen} setSeverity={setSeverity}/>
          )}
          {selectedOption === "periodic_report" && (
            <PeriodicForm setData={setData} setSnackOpen={setSnackOpen}  setMessageOpen={setMessageOpen} setSeverity={setSeverity}/>
          )} */}
        </Container>
        {data.holdings?.holding?.length > 0 && (
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <DataTable data={data} />
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        )}
      </Box>
    </Box>
  );
}
